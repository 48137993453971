import React, { useState } from 'react';

import Section from '../section';
import SummaryItem from '../summary-item';

const classes = {
  sectionReset: '',
  input: 'w-full bg-transparent block mb-4 px-2 py-2 outline-none border-b hover:border-b-1 focus:border-b-1 max-w-2xl',
};

const BlogPosts = ({ posts }) => {
  const [searchText, setSearchText] = useState('');

  const filterSearchResults = (postsToFilter) => {
    if (!searchText) return postsToFilter;

    return [...postsToFilter].filter(p => {
      const { title = '', description = '', date = '', slug = '' } = p.node.frontmatter
      const stringToSearch = `${title} ${description} ${date} ${slug}`.toLowerCase().trim() // todo only keep alphabets and numbers
      return stringToSearch.includes(searchText)
    })
  }

  return (
    <Section
      title="All Blog Posts"
      className={classes.sectionReset}
    >
      {/* <input
        className={classes.input}
        placeholder='Search blogs'
        onChange={(e) => setSearchText((e.target.value || '').trim().toLowerCase())}
      /> */}
      {filterSearchResults(posts).map((post) => (
        <SummaryItem
          key={post.node.fields.slug}
          name={post.node.frontmatter.title}
          date={post.node.frontmatter.date}
          description={post.node.frontmatter.description}
          link={post.node.fields.slug}
          internal
          hideLinkIcon
        />
      ))}
    </Section>
  );
};

export default BlogPosts;
